<template>
  <section class="styleguide bg-primary">
    <div class="flex -column justify-center h-100">
      <h1>This is a large heading</h1>
      <h2>This is a medium heading</h2>
      <h3>This is a standard heading</h3>
      <h4>This is a small heading</h4>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio, totam. Quod repellendus quasi velit nesciunt commodi vero soluta officia doloribus mollitia non qui nulla, quis repellat quibusdam odio aliquam tempora!</p>
      <hr>
      <div class="flex align-center my-1">
        <button class="button">Generic button</button>
        <button class="button cta mx-1">CTA button</button>
      </div>
      <div class="flex align-center">
        <button class="button -hollow">Generic button hollow</button>
        <button class="button cta -hollow mx-1">CTA button hollow</button>
      </div>
    </div>
  </section>
</template>
