<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_275_86)">
    <path
      d="M18.5625 13.75H17.1875C17.0052 13.75 16.8303 13.8224 16.7014 13.9514C16.5724 14.0803 16.5 14.2552 16.5 14.4375V19.25H2.75V5.5H8.9375C9.11984 5.5 9.2947 5.42757 9.42364 5.29864C9.55257 5.1697 9.625 4.99484 9.625 4.8125V3.4375C9.625 3.25516 9.55257 3.0803 9.42364 2.95136C9.2947 2.82243 9.11984 2.75 8.9375 2.75H2.0625C1.51549 2.75 0.990886 2.9673 0.604092 3.35409C0.217298 3.74089 0 4.26549 0 4.8125L0 19.9375C0 20.4845 0.217298 21.0091 0.604092 21.3959C0.990886 21.7827 1.51549 22 2.0625 22H17.1875C17.7345 22 18.2591 21.7827 18.6459 21.3959C19.0327 21.0091 19.25 20.4845 19.25 19.9375V14.4375C19.25 14.2552 19.1776 14.0803 19.0486 13.9514C18.9197 13.8224 18.7448 13.75 18.5625 13.75ZM20.396 0H13.5781C13.259 0 12.953 0.126757 12.7274 0.352387C12.5018 0.578017 12.375 0.884036 12.375 1.20312V2.00707C12.3795 2.16501 12.4151 2.32052 12.4797 2.46472C12.5443 2.60891 12.6367 2.73896 12.7516 2.84745C12.8664 2.95594 13.0015 3.04074 13.1492 3.09701C13.2968 3.15328 13.4541 3.17991 13.6121 3.17539L16.5 3.09375L5.80336 13.7109L5.80078 13.7135C5.6074 13.9069 5.49876 14.1692 5.49876 14.4427C5.49876 14.7161 5.6074 14.9784 5.80078 15.1718L6.82945 16.1966L6.83203 16.1992C7.02589 16.3917 7.28819 16.4994 7.56137 16.4987C7.83454 16.4979 8.09627 16.3889 8.2891 16.1954L18.9062 5.5L18.8255 8.38836V8.42188C18.8255 8.74096 18.9522 9.04698 19.1779 9.27261C19.4035 9.49824 19.7095 9.625 20.0286 9.625H20.7969C21.116 9.625 21.422 9.49824 21.6476 9.27261C21.8732 9.04698 22 8.74096 22 8.42188V1.60402C22 1.17861 21.831 0.77062 21.5302 0.469808C21.2294 0.168995 20.8214 0 20.396 0V0Z"
      fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_275_86">
      <rect width="22" height="22" fill="white" />
    </clipPath>
  </defs>
</svg>
</template>
