<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" :style="styles">
  <path
    d="M14.4124 8.12965C14.6737 5.73461 17.9765 5.30499 18.8418 7.55349C18.9717 7.89129 19.1795 8.19365 19.4483 8.43596C19.7171 8.67827 20.0394 8.85368 20.3888 8.94792C20.7382 9.04216 21.1049 9.05256 21.4592 8.97827C21.8134 8.90399 22.145 8.74711 22.4271 8.52043C24.3056 7.01219 26.9457 9.04328 25.9683 11.2464C25.8216 11.577 25.755 11.9375 25.7738 12.2987C25.7926 12.6598 25.8964 13.0115 26.0767 13.325C26.257 13.6385 26.5088 13.905 26.8115 14.1029C27.1142 14.3008 27.4593 14.4245 27.8188 14.4639C30.2139 14.7252 30.6435 18.028 28.395 18.8933C28.0572 19.0232 27.7548 19.231 27.5125 19.4998C27.2702 19.7686 27.0948 20.0909 27.0006 20.4403C26.9063 20.7897 26.8959 21.1564 26.9702 21.5106C27.0445 21.8649 27.2014 22.1965 27.4281 22.4786C28.9363 24.3571 26.9052 26.9972 24.7021 26.0198C24.3715 25.8731 24.011 25.8065 23.6498 25.8253C23.2887 25.8441 22.937 25.9479 22.6235 26.1282C22.31 26.3085 22.0435 26.5603 21.8456 26.863C21.6477 27.1657 21.524 27.5108 21.4846 27.8703C21.2233 30.2654 17.9205 30.695 17.0552 28.4465C16.9253 28.1087 16.7175 27.8063 16.4487 27.564C16.1799 27.3217 15.8576 27.1463 15.5082 27.0521C15.1588 26.9578 14.792 26.9474 14.4378 27.0217C14.0836 27.096 13.752 27.2529 13.4699 27.4796C11.5914 28.9878 8.95133 26.9567 9.92864 24.7536C10.0754 24.423 10.142 24.0625 10.1232 23.7013C10.1043 23.3401 10.0006 22.9885 9.82025 22.675C9.63995 22.3615 9.38821 22.095 9.0855 21.8971C8.78279 21.6992 8.43766 21.5755 8.07815 21.536C5.68311 21.2748 5.25349 17.972 7.502 17.1067C7.8398 16.9768 8.14216 16.769 8.38447 16.5002C8.62678 16.2314 8.80219 15.9091 8.89642 15.5597C8.99066 15.2103 9.00106 14.8435 8.92677 14.4893C8.85249 14.1351 8.69562 13.8035 8.46893 13.5214C6.96069 11.6429 8.99178 9.00283 11.1949 9.98014C12.6164 10.6127 14.2427 9.67781 14.4124 8.12965Z"
    stroke="auto" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path
    d="M21.9151 17.484C22.0519 18.536 21.7652 19.5993 21.1181 20.44C20.471 21.2806 19.5165 21.8297 18.4645 21.9666C17.4124 22.1034 16.3492 21.8168 15.5085 21.1696C14.6679 20.5225 14.1187 19.568 13.9819 18.516C13.8451 17.464 14.1317 16.4007 14.7788 15.56C15.426 14.7194 16.3805 14.1703 17.4325 14.0334C18.4845 13.8966 19.5478 14.1832 20.3884 14.8304C21.2291 15.4775 21.7782 16.432 21.9151 17.484V17.484Z"
    stroke="auto" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>
</template>
<script>
export default {
  props: {
    styles: {
      default: null,
      type: String,
      required: false
    }
  }
}
</script>
